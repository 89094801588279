import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import CustomButton from '../../components/general/button';
import Sticky from 'react-sticky-el';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import OrphanTabContent from './orphan-tab-content-new';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

const OrphanTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    orphanFeastProducts,
    emergencyOrphanSupportProducts,
    feedPalestinianOrphansProducts,
    supportAnOrphanProducts,
    lang = 'en',
    arabic = false,
  } = props;

  const selectedCurrencyStore = useSelector(selectSelectedCurrency);

  const [isTabsFixed, setIsTabsFixed] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const contentRef = useRef(null);
  const [scrollLeftEnd] = useState(true);
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    mode: 'free-snap',
    slides: {
      perView: 2.3,
      spacing: 10,
    },
    breakpoints: {
      '(min-width: 300px)': {
        slides: { perView: 1.8, spacing: 10 },
      },
      '(min-width: 375px)': {
        slides: { perView: 2.14, spacing: 10 },
      },
      '(min-width: 420px)': {
        slides: { perView: 2.4, spacing: 10 },
      },
    },
  });
  const tabButtonsProducts = [
    {
      tabTitle: lang === 'fr' ? 'Urgence Palestinienne' : lang === 'ar' ? 'فلسطين الطوارئ' : 'Orphan Feast',
      products: orphanFeastProducts
    },
    {
      tabTitle: lang === 'fr' ? 'Installation des réfugiés palestiniens' : lang === 'ar' ? 'مستوطنة اللاجئين الفلسطينيين' : 'Feed Palestinian Orphans',
      products: feedPalestinianOrphansProducts
    },
    {
      tabTitle: lang === 'fr' ? 'La faim en Palestine' : lang === 'ar' ? 'فلسطين جوعى' : 'Support An Orphan',
      products: supportAnOrphanProducts
    },
    {
      tabTitle: lang === 'fr' ? 'sauver les orphelins' : lang === 'ar' ? 'إنقاذ الأيتام' : 'Emergency Orphan Support',
      products: emergencyOrphanSupportProducts
    },
  ];

  const excludedTabTitles = new Set([
    lang === 'fr'
      ? 'Installation des réfugiés palestiniens'
      : lang === 'ar'
      ? 'مستوطنة اللاجئين الفلسطينيين'
      : 'Feed Palestinian Orphans',
    lang === 'fr'
      ? 'sauver les orphelins'
      : lang === 'ar'
      ? 'إنقاذ الأيتام'
      : 'Emergency Orphan Support',
  ]);

  const filteredTabButtonsProducts = tabButtonsProducts.filter(
    ({ tabTitle }) => !excludedTabTitles.has(tabTitle)
  );

  /* -------------------------------------------------------------------------- */
  const handleTabChange = index => {
    setActiveTabProducts(index);
    instanceRef.current?.moveToIdx(index - 1, false);
  };

  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]-" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <div
        className={`${activeTabProducts === 1 || activeTabProducts === 3 ? '' : 'md:container'} w-full mx-auto ${scrollLeftEnd ? 'pl-2 pr-0' : ''
          } md:px-0 py-4 md:py-12`}
      >
        <div className="hidden sm:flex items-center w-full overflow-x-auto">
          <div className="flex gap-1 min-[768px]:gap-2 items-center md:justify-center w-full min-w-[500px] max-w-[600px] mx-auto">
            {filteredTabButtonsProducts.map(({ tabTitle }, index) => (
              <CustomButton
                key={`btn_${index}`}
                title={tabTitle}
                onClick={handleTabChange.bind(this, index)}
                className={`${index === activeTabProducts ? '!bg-DarkerBlue text-white' : '!bg-[#F8F8F8] !border !border-DarkerBlue !text-DarkerBlue'
                  } mr-2 md:mr-0 min-w-fit !rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-["Montserrat"] font-bold'
                  }`}
              />
            ))}
          </div>
        </div>
        <div className="flex sm:hidden [&>div]:w-full z-10 bg-white overflow-hidden">
          <Sticky
            stickyStyle={{ top: '64px', bottom: 'unset' }}
            stickyClassName="!w-full flex justify-end items-center shadow-[rgba(189,189,189,0.5)_0px_15px_25px_-1px] z-10 bg-white left-0"
            mode="top"
            onFixedToggle={() => setIsTabsFixed(!isTabsFixed)}
          >
            <div ref={sliderRef} className="keen-slider !min-h-[0px]">
              {filteredTabButtonsProducts.map(({ tabTitle }, index) => (
                <div key={`btn_${index}`} className="keen-slider__slide">
                  <button
                    onClick={() => handleTabChange(index)}
                    className={`${index === activeTabProducts ? 'bg-DarkerBlue text-[#fff]' : 'bg-[#fff] text-DarkerBlue'
                      } border border-DarkerBlue rounded-3xl p-2 w-[100%] 2xs:text-[11px] xs:text-[12px] flex justify-center items-center`}
                  >
                    {tabTitle}
                  </button>
                </div>
              ))}
            </div>
          </Sticky>
        </div>
      </div>
      <div className={`w-full mx-auto px-4 md:px-0 py-4 md:py-12 bg-[#253B7E]`}>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 mt-5 mb-5 md:mt-10">
          <div className="flex flex-col w-full justify-center">
            {filteredTabButtonsProducts.map((item, index) => {
              return item.products.length && activeTabProducts === index && <div key={`tabs_orphasn${index}`} ref={contentRef}>
                <OrphanTabContent
                arabic={arabic}
                lang={lang}
                products={item.products}
                currency={selectedCurrency}
                showDots={false}
                showArrows={false}
                showMultiProductsOnRow={false}
                viewAppealsLink={null}
              />
              </div>
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default OrphanTabs;
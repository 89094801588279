/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { orphansFaqs } from './faqs';
import Accordion from '../../components/general/accordion';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import { selectProducts } from '../../store/products';
import banner from '../../images/orphans/Orphans-Generic-Hero-Banner-Desktop.jpg';
// import bannerMobile from '../../images/orphans/Orphans-Generic-Hero-Banner-Mobile.jpg';
import { OurPromiseSection } from '../../components/general';
// import OrphanTabsSection from './orphan-tabs';
import BottomNav from './bottomNav';
import OrphanTabs from './orphan-tabs-new';
import VideosSection from '../../components/general/videosSection';
import { videos } from '../crisis-and-emergencies/fixtures';
import { helpUseReachMoreSection, worldProgramSection } from './orphans-content';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import WidgetHome from '../../components/widgets/widgetHomeOrdered';

// const images = [Orphan1, Orphan2, Orphan3, Orphan4, Orphan5];

const OrphansUat = ({ lang = 'en' }) => {
  const bannerFr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Desktop-FR.webp';
  const bannerMobilFr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Mobile-FR.webp';
  const bannerAr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Desktop-AR.webp';
  const bannerMobileAr = 'https://cdn.matwproject.org/images/banners/Orphans-Generic-Hero-Banner-Mobile-AR.webp';
  const products = useSelector(state => selectProducts(state));
  const [orphanFeastProducts, setOrphanFeastProducts] = useState([0, 0, 0]);
  const [feedPalestinianOrphansProducts, setFeedPalestinianOrphansProducts] = useState([0, 0, 0]);
  const [emergencyOrphanSupportProducts, setEmergencyOrphanSupportProducts] = useState([0]);
  const [supportAnOrphanProducts, setSupportAnOrphanProductsList] = useState([0, 0, 0, 0]);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const faqsRef = useRef(null);
  const moreInfoRef = useRef(null);
  const appealsRef = useRef(null);
  const [activeSection, setActiveSection] = useState('');
  const [showScrollMore, setShowScrollMore] = useState(false);
  const startRef = useRef(null);
  const endRef = useRef(null);

  const scrollToRef = ref => {
    if (ref.current) {
      // Calculate offset position above the element
      const offsetPosition = ref.current.offsetTop - 150; // Adjust 100 pixels above the element
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.3,
    });

    if (faqsRef.current) observer.observe(faqsRef.current);
    if (moreInfoRef.current) observer.observe(moreInfoRef.current);
    if (appealsRef.current) observer.observe(appealsRef.current);

    return () => {
      if (faqsRef.current) observer.unobserve(faqsRef.current);
      if (moreInfoRef.current) observer.unobserve(moreInfoRef.current);
      if (appealsRef.current) observer.unobserve(appealsRef.current);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (startRef.current && endRef.current) {
        const startPosition = startRef.current.getBoundingClientRect().top;
        const endPosition = endRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;

        if (startPosition < windowHeight && endPosition > 870) {
          setShowScrollMore(true);
        } else {
          setShowScrollMore(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getAllProducts = () => {
    setIsLoaded(true);
    let orphanFeastProductsList = [];
    let feedPalestinianOrphansProductsList = [];
    let emergencyOrphanSupportProductsList = [];
    let supportAnOrphanProductsList = [];
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'FST-DEL-F150-23-01-010') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Festin pour 150 orphelins` : lang === 'ar' ? `وليمة لـ 150 يتيم` : 'Feast for 150 Orphans',
              description:
                lang === 'fr'
                  ? `Faites une différence dans la vie des orphelins en parrainant (Wallima) un festin.`
                  : lang === 'ar'
                  ? `أحدث فرقا في حياة الأيتام من خلال رعاية وليمة غذائية `
                  : inner_item_product.description,
            });
            orphanFeastProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'FST-DEL-F50-2023-01-009') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Festin pour 50 orphelins` : lang === 'ar' ? `وليمة لـ 50 يتيم` : 'Feast for 50 Orphan',
              description:
                lang === 'fr'
                  ? `Faites une différence dans la vie des orphelins en parrainant (Wallima) un festin.`
                  : lang === 'ar'
                  ? `أحدث فرقا في حياة الأيتام من خلال رعاية وليمة غذائية `
                  : inner_item_product.description,
            });
            orphanFeastProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'FST-DEL-F25-23-01-008') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Festin pour 25 orphelins` : lang === 'ar' ? `وليمة لـ 25 يتيم` : 'Feast for 25 Orphan',
              description:
                lang === 'fr'
                  ? `Faites une différence dans la vie des orphelins en parrainant (Wallima) un festin.`
                  : lang === 'ar'
                  ? `أحدث فرقا في حياة الأيتام من خلال رعاية وليمة غذائية `
                  : inner_item_product.description,
            });
            orphanFeastProductsList[2] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-315') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Nourrir 1 orphelin` : lang === 'ar' ? `إطعام يتيم واحد` : 'Feed 1 Orphan',
              // description:
              //   lang === 'fr'
              //     ? ``
              //     : lang === 'ar'
              //       ? ``
              //       : inner_item_product.description
            });
            feedPalestinianOrphansProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Nourrir 10 orphelins` : lang === 'ar' ? `إطعام عشرة أيتام` : 'Feed 10 Orphans',
            });
            feedPalestinianOrphansProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Nourrir 20 orphelins` : lang === 'ar' ? `إطعام عشرين يتيم` : 'Feed 20 Orphans',
            });
            feedPalestinianOrphansProductsList[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-23-01-204') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr' ? `Aide d’urgence aux orphelins` : lang === 'ar' ? `دعم الأيتام في حالات الطوارئ` : 'Emergency Orphan Support',
              description:
                lang === 'fr'
                  ? `Aider à fournir une aide d’urgence aux orphelins palestiniens, notamment des besoins essentiels comme la nourriture et les vêtements, le soutien psychologique, les soins de santé et l’aide à l’éducation.`
                  : lang === 'ar'
                  ? `ساعد في توفير الدعم العاجل للأيتام الفلسطينيين، بما في ذلك تلبية الاحتياجات الأساسية مثل الطعام والملابس، والدعم النفسي، والرعاية الصحية، والمساعدة التعليمية.`
                  : inner_item_product.description,
            });
            emergencyOrphanSupportProductsList[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'ORP-GEN-24-01') {
            supportAnOrphanProductsList[0] = {
              ...inner_item_product,
            };
          }
          if (inner_item_product.creator == 'ORP-GEN-24-02') {
            supportAnOrphanProductsList[1] = {
              ...inner_item_product,
            };
          }
          if (inner_item_product.creator == 'ORP-GEN-24-03') {
            supportAnOrphanProductsList[2] = {
              ...inner_item_product,
            };
          }
        });
      });
    });

    orphanFeastProductsList = orphanFeastProductsList.filter(n => n);
    feedPalestinianOrphansProductsList = feedPalestinianOrphansProductsList.filter(n => n);
    emergencyOrphanSupportProductsList = emergencyOrphanSupportProductsList.filter(n => n);
    supportAnOrphanProductsList = supportAnOrphanProductsList.filter(n => n);
    console.log(
      'emergencyOrphanSupportProducts=',
      supportAnOrphanProductsList,
      emergencyOrphanSupportProductsList,
      feedPalestinianOrphansProductsList,
    );
    setOrphanFeastProducts(orphanFeastProductsList);
    setFeedPalestinianOrphansProducts(feedPalestinianOrphansProductsList);
    setEmergencyOrphanSupportProducts(emergencyOrphanSupportProductsList);
    setSupportAnOrphanProductsList(supportAnOrphanProductsList);
  };

  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <NewHelmetLayout className="bg-white" hideFooter>
      {seo && (
        <Helmet>
          <title>{`${seo['orphans']['orphans']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo['orphans']['orphans']['description']}`} />
        </Helmet>
      )}

      {/* <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <OrphanCrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-GEN-MOR-2023-01-119',
            label: lang === 'fr' ? `Les orphelins dans le monde` : lang === 'ar' ? `الأيتام حول العالم` : 'Orphans Around The World',
            value: 105,
          }}
          lang={lang}
        />
      </section> */}

      <section className="flex justify-center">
        <img src={lang === 'fr' ? bannerFr : lang === 'ar' ? bannerAr : banner} alt="banner" className="max-[500px]:hidden w-full" />
        <img
          src={lang === 'fr' ? bannerMobilFr : lang === 'ar' ? bannerMobileAr : 'https://cdn.matwproject.org/images/banners/orphans-banner.jpg'}
          alt="banner"
          className="min-[501px]:hidden w-full"
        />
      </section>
      {/* <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 md:flex justify-center items-center">
            <CrisisWidget
              floating
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={{
                creator: 'MKD-GEN-MOR-2023-01-119',
                label: lang === 'fr' ? `Les orphelins dans le monde` : lang === 'ar' ? `الأيتام حول العالم` : 'Orphans Around The World',
                value: 105,
              }}
              lang={lang}
            />
          </div>
        </section>
      </section>*/}

      <section className="z-30">
        <WidgetHome
          selectedCurrency={() => 0}
          defaultProduct={{
            creator: 'DD-ORP-GEN-24-04',
            label: lang === 'fr' ? `Soutenir un orphelin avec de la nourriture` : lang === 'ar' ? `دعم يتيم بالطعام` : 'Support an orphan with food',
            value: 776,
          }}
          reqFromOrphanPage={true}
        />
      </section>

      <div id="appeals" ref={appealsRef}>
        {/* <OrphanTabsSection
          orphanFeastProductsList={orphanFeastProducts}
          feedPalestinianOrphansProductsList={feedPalestinianOrphansProducts}
          emergencyOrphanSupportProductsList={emergencyOrphanSupportProducts}
          supportAnOrphanProductsList={supportAnOrphanProducts}
        /> */}
        <OrphanTabs
          orphanFeastProducts={orphanFeastProducts}
          emergencyOrphanSupportProducts={emergencyOrphanSupportProducts}
          feedPalestinianOrphansProducts={feedPalestinianOrphansProducts}
          supportAnOrphanProducts={supportAnOrphanProducts}
          lang={lang}
        />
      </div>
      {/* ----------------- videos section ---------------- */}
      <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
      {/* ----------------- videos section --END-------------- */}
      <OurPromiseSection lang={lang} />
      <main className="max-w-[1440px] mx-auto">
        <section className="xl:px-10 mt-5 sm:mt-12" id="more-info" ref={moreInfoRef}>
          <div className="flex justify-center">
            <div className="text-left font-brandingBold sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12">
              {helpUseReachMoreSection.title.main[lang]}
              <br />
              <span className="text-[#F60362] mr-2">{helpUseReachMoreSection.title.highlighted[lang]}</span>
            </div>
          </div>
          <div className="px-4 md:px-6 mt-2 sm:mt-10 leading-5">
            <div className="text-[#78716C] lg:px-20 leading-6 text-[18px] text-left  font-brandingMedium">
              {helpUseReachMoreSection.description.section1[lang]}
              <br />
              <br />
              {helpUseReachMoreSection.description.section2[lang]}
              <br />
              <br />
              {helpUseReachMoreSection.description.section3[lang]}
            </div>
          </div>
        </section>
        {/* scroll more start */}
        <section className="xl:px-20 mt-8" id="scroll-more-start" ref={startRef}>
          <div className="relative overflow-hidden flex flex-wrap">
            <div
              className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  "
            >
              <div className="leading-[50px] w-full text-[24px] sm:text-[50px] md:text-[30px] lg:text-[28px] px-8 text-white text-center font-brandingBold">
                The Prophet PBUH said:
              </div>
              <p className="text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center">
                “Whoever takes in an orphan among the Muslims to raise, to feed him and give him drink, Allah admits him into Paradise without a
                doubt, unless he has done a sin for which he is not forgiven.”
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <div className="basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[300px] min-[410px]:min-h-[300px] sm:h-[300px] md:h-[600px] lg:h-[1000px]">
              <iframe
                className="video pointer-events-none"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                frameBorder={0}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://www.youtube.com/embed/ciUzaL90cxA?controls=0&autoplay=1&loop=1&mute=1&playlist=ciUzaL90cxA`}
              ></iframe>
            </div>
          </div>
        </section>

        <section className="xl:px-20 sm:py-8">
          <div>
            <div className="flex justify-center pt-4">
              <div className="text-left font-brandingBold sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12">
                {worldProgramSection.title[lang]}
              </div>
            </div>
            <div className="px-4 md:px-28 text-[18px] mt-8 leading-5">
              <p className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                {worldProgramSection.description.section1[lang]}
                <br />
                <br />
                {worldProgramSection.description.section2[lang]}
                <br />
                <br />
                {worldProgramSection.description.section3[lang]}
              </p>
              <div>
                <h2 className="text-[#253B7E] my-5 text-2xl sm:text-[45px] sm:leading-[54px] font-bold">
                  {worldProgramSection.list.title.top[lang]}
                  <br />
                  {worldProgramSection.list.title.bottom[lang]}
                </h2>
                <ul className="text-[#78716C] list-disc pl-3 leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px]">
                  {worldProgramSection.list.listItems.map(item => (
                    <li>{item[lang]}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="px-4 xl:px-20 bg-white" id="faqs" ref={faqsRef}>
          <h1 className="text-[#253B7E] text-center my-4 text-[30px] sm:text-[60px] font-bold">
            {lang === 'fr' ? (
              <>
                <span className="text-[#F60362]">FAQ </span>
                sur les dons de la MATW
              </>
            ) : lang === 'ar' ? (
              ''
            ) : (
              <>
                MATW donations
                <span className="text-[#F60362]"> FAQ’s</span>
              </>
            )}
          </h1>
          <div className="grid grid-cols-3 gap-x-16 gap-y-8">
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[0].question[lang]}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[0].answer[lang]}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[1].question[lang]}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[1].answer[lang]}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{orphansFaqs[2].question[lang]}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{orphansFaqs[2].answer[lang]}</p>
            </div>
          </div>
          <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
            {orphansFaqs.map((item, index) => {
              return (
                index > 2 && (
                  <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                    <Accordion key={`keyallfaq${index}`} shadow title={item.question[lang]} index={index} level={1} noBorder={true}>
                      {item.answer[lang]}
                    </Accordion>
                  </div>
                )
              );
            })}
          </div>
        </section>
        {/* Scroll more end */}
        <div id="scroll-more-end" ref={endRef}></div>
      </main>
      {showScrollMore && (
        <>
          <div
            className="fixed xs:bottom-12 md:bottom-0 left-1/2 transform -translate-x-1/2 w-full text-center text-[#14A2DC] text-[14px] font-medium py-2"
            style={{ zIndex: 999, background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0.01%, #FFFFFF 100%)' }}
          >
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            scroll more
          </div>
        </>
      )}
      <BottomNav activeSection={activeSection} scrollToRef={scrollToRef} faqsRef={faqsRef} moreInfoRef={moreInfoRef} appealsRef={appealsRef} />
    </NewHelmetLayout>
  );
};

export default OrphansUat;
